import React from 'react';
import firebase from 'firebase/app';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  icon: {
    color: 'inherit',
  },
});

class EditTaskHeader extends React.Component {
  state = {
    anchor: null,
    confirmDialogOpen: false,
  };

  ownsTask = () => {
    const task = this.props.task;
    const user = firebase.auth().currentUser;
    return task && user && task.owner === user.uid;
  };

  handleClick = (event) => {
    this.setState({ anchor: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchor: null });
  };

  handleRemoveTask = () => {
    this.setState({ anchor: null, confirmDialogOpen: true });
  };

  closeConfirmDialog = () => {
    this.setState({ confirmDialogOpen: false });
  };

  handleConfirmRemoveTask = () => {
    this.closeConfirmDialog();
    this.props.removeTask(this.ownsTask());
  };

  render() {
    const { classes, loading, cancel, task } = this.props;
    const { anchor, confirmDialogOpen } = this.state;

    return <AppBar position='fixed'>
      <Toolbar>
        <Typography variant='h6' className={classes.title}>
          {task ? 'Edit' : 'Create'} task
        </Typography>
        <IconButton
          className={classes.icon}
          size={'small'}
          onClick={cancel}>
          <CloseIcon />
        </IconButton>
        {task && <IconButton
          className={classes.icon}
          size={'small'}
          onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>}
        <Menu
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleRemoveTask}>
            {this.ownsTask() ? 'Delete task' : 'Leave task'}
          </MenuItem>
        </Menu>
      </Toolbar>
      {loading && <LinearProgress className={classes.progress} />}

      <Dialog open={confirmDialogOpen} onClose={() => this.closeConfirmDialog()}>
        <DialogTitle>{this.ownsTask() ? 'Delete task?' : 'Leave task?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.ownsTask() ?
              'Are you sure you want to delete this task? This action can not be reverted.' :
              'Are you sure you want to leave this task? You can join again later by getting invited.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleConfirmRemoveTask()} color="primary">
            {this.ownsTask() ? 'Delete task' : 'Leave task'}
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>;
  }
}

export default withStyles(styles)(EditTaskHeader);
