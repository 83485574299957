import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = (theme) => ({
  wrapper: {
    position: 'relative',
    transition: 'max-height 0.5s ease',
    maxHeight: '1000px',
    transformOrigin: 'top',
    overflow: 'hidden',
    width: '100%',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    color: 'white',
    backgroundColor: '#f44336',
    boxSizing: 'border-box',
  },
  listItem: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    height: '100%',
    display: 'flex',
  },
  bouncingListItem: {
    position: 'relative',
    transition: 'transform 0.5s ease-out',
    width: '100%',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    height: '100%',
    display: 'flex',
  },
});

class SwipeableListItem extends React.Component {
  // DOM Refs
  listElement;
  wrapper;
  background;
  deleteLeft;
  deleteRight;

  // Drag & Drop
  dragStartX = 0;
  left = 0;
  dragged = false;

  // FPS Limit
  startTime;
  fpsInterval = 1000 / 60;

  constructor(props) {
    super(props);

    this.listElement = null;
    this.wrapper = null;
    this.background = null;
    this.deleteLeft = null;
    this.deleteRight = null;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onDragStartMouse = this.onDragStartMouse.bind(this);
    this.onDragStartTouch = this.onDragStartTouch.bind(this);
    this.onDragEndMouse = this.onDragEndMouse.bind(this);
    this.onDragEndTouch = this.onDragEndTouch.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.onSwiped = this.onSwiped.bind(this);
  }

  onDragStartMouse(evt) {
    this.onDragStart(evt.clientX);
    window.addEventListener('mousemove', this.onMouseMove);
  }

  onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener('touchmove', this.onTouchMove);
  }

  onDragStart(clientX) {
    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.className = classnames(this.props.classes.listItem, this.props.className);
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  }

  onMouseMove(evt) {
    this.updateMousePos(evt.clientX);
  }

  onTouchMove(evt) {
    this.updateMousePos(evt.targetTouches[0].clientX);
  }

  updateMousePos(pos) {
    this.left = pos - this.dragStartX;
    if (this.left > 0) {
      this.deleteLeft.style.opacity = 1;
      this.deleteRight.style.opacity = 0;
    } else {
      this.deleteLeft.style.opacity = 0;
      this.deleteRight.style.opacity = 1;
    }
  }

  onDragEndMouse() {
    window.removeEventListener('mousemove', this.onMouseMove);
    this.onDragEnd();
  }

  onDragEndTouch() {
    window.removeEventListener('touchmove', this.onTouchMove);
    this.onDragEnd();
  }

  onDragEnd() {
    if (this.dragged) {
      this.dragged = false;

      const threshold = this.props.threshold || 0.3;

      if (Math.abs(this.left) >= this.listElement.offsetWidth * threshold) {
        const pos = this.listElement.offsetWidth * 2;
        this.left = this.left < 0 ? -pos : pos;
        this.wrapper.style.maxHeight = 0;
        this.onSwiped();
      } else {
        this.left = 0;
      }

      this.listElement.className = classnames(this.props.classes.bouncingListItem, this.props.className);
      this.listElement.style.transform = `translateX(${this.left}px)`;
    }
  }

  updatePosition() {
    if (this.dragged) requestAnimationFrame(this.updatePosition);

    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      this.listElement.style.transform = `translateX(${this.left}px)`;
      this.startTime = Date.now();
    }
  }

  onSwiped() {
    this.props.onDeleted();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onDragEndMouse);
    window.addEventListener('touchend', this.onDragEndTouch);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onDragEndMouse);
    window.removeEventListener('touchend', this.onDragEndTouch);
  }

  render() {
    const { classes, className, children } = this.props;

    return <div className={classes.wrapper} ref={div => (this.wrapper = div)}>
      <div className={classes.background} ref={div => (this.background = div)}>
        <DeleteIcon ref={div => (this.deleteLeft = div)} />
        <DeleteIcon ref={div => (this.deleteRight = div)} />
      </div>
      <div
        className={classnames(classes.listItem, className)}
        ref={div => (this.listElement = div)}
        onMouseDown={this.onDragStartMouse}
        onTouchStart={this.onDragStartTouch}
      >
        {children}
      </div>
    </div>;
  }
}

export default withStyles(styles)(SwipeableListItem);
