import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import UserRow from './UserRow';

function ClipboardTextIcon(props) {
  return <SvgIcon {...props}>
    <path
      d="M7,15h7v2L7,17zM7,11h10v2L7,13zM7,7h10v2L7,9zM19,3h-4.18C14.4,1.84 13.3,1 12,1c-1.3,0 -2.4,0.84 -2.82,2L5,3c-0.14,0 -0.27,0.01 -0.4,0.04 -0.39,0.08 -0.74,0.28 -1.01,0.55 -0.18,0.18 -0.33,0.4 -0.43,0.64 -0.1,0.23 -0.16,0.49 -0.16,0.77v14c0,0.27 0.06,0.54 0.16,0.78s0.25,0.45 0.43,0.64c0.27,0.27 0.62,0.47 1.01,0.55 0.13,0.02 0.26,0.03 0.4,0.03h14c1.1,0 2,-0.9 2,-2L21,5c0,-1.1 -0.9,-2 -2,-2zM12,2.75c0.41,0 0.75,0.34 0.75,0.75s-0.34,0.75 -0.75,0.75 -0.75,-0.34 -0.75,-0.75 0.34,-0.75 0.75,-0.75zM19,19L5,19L5,5h14v14z" />
  </SvgIcon>;
}

const styles = (theme) => ({
  root: {
    marginTop: 16,
  },
  title: {
    marginBottom: 8,
    fontSize: '1rem',
    fontWeight: 300,
    marginTop: -8,
  },
  actions: {
    float: 'right',
    '& > *': {
      marginLeft: 8,
    }
  },
});

const calculateUserWeights = (users, actions) => {
  const weights = {}, executors = [];
  executors.push(...Object.entries(users).map(([id, user]) => [user.joinedAt, () => {
    const values = Object.values(weights);
    const sum = values.reduce((a, b) => a + b, 0);
    weights[id] = values.length ? sum / values.length : 0;
  }]));
  executors.push(...(actions || []).map((log) => [log.timestamp, () => {
    if (weights.hasOwnProperty(log.performedBy)) weights[log.performedBy] += log.option.weight;
  }]));
  executors.sort((a, b) => a[0] - b[0]).forEach(([_, execute]) => execute());
  return weights;
};

const calculateSortedUsers = (users, weights) => {
  return Object.entries(users)
    .sort((a, b) => a[0] - b[0])
    .sort((a, b) => weights[a[0]] - weights[b[0]]);
};

class Task extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const { task, classes, addActionLog, editActionLog, editTask } = this.props;
    const { opened } = this.state;

    const weights = calculateUserWeights(task.users, task.actionLog);
    const [first, ...users] = calculateSortedUsers(task.users, weights);
    const firstWeight = weights[first[0]];

    const getDiff = (entry) => entry === first ? -1 : (weights[entry[0]] - firstWeight);
    const renderUserRow = (entry) => <UserRow
      key={entry[0]}
      user={entry[1]}
      diff={getDiff(entry)}
      opened={opened}
      options={task.options}
      addActionLog={(option) => addActionLog({ option, performedBy: entry[0], timestamp: new Date().valueOf() })}
    />;

    const taskActions = <div className={classes.actions}>
      <IconButton
        component={'div'}
        size={'small'}
        onClick={(event) => {
          event.stopPropagation();
          editActionLog();
        }}
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}>
        <ClipboardTextIcon fontSize={'small'} />
      </IconButton>
      <IconButton
        component={'div'}
        size={'small'}
        onClick={(event) => {
          event.stopPropagation();
          editTask();
        }}
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}>
        <EditIcon fontSize={'small'} />
      </IconButton>
    </div>;

    return <Card className={classes.root}>
      <CardActionArea onClick={() => this.setState(({ opened }) => ({ opened: !opened }))}>
        <CardContent>
          <div>
            {opened && taskActions}
            <Typography variant="subtitle1" component="h3" className={classes.title}>
              {task.title || ''}
            </Typography>
          </div>
          {renderUserRow(first)}
          {users.length && <Collapse in={opened} timeout="auto" unmountOnExit>
            {users.map(renderUserRow)}
          </Collapse> || null}
        </CardContent>
      </CardActionArea>
    </Card>;
  }
}

export default withStyles(styles)(Task);
