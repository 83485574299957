import React from 'react';
import firebase from 'firebase/app';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';

const styles = (theme) => ({
  root: {
    marginTop: 16,
  },
  title: {
    width: '100%',
  },
  groupLabel: {
    marginTop: 16,
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  optionGroups: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  optionBorder: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  options: {},
  chip: {
    backgroundColor: 'transparent',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  avatar: {},
  snackbar: {
    backgroundColor: '#7cb342',
  },
  avatarInvite: {
    backgroundColor: 'transparent',
    border: '1px solid',
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
});

const OPTIONS = [
  {
    id: 'simple',
    title: 'Simple',
    options: [
      {
        label: '✔️',
        weight: 1,
      },
    ],
  },
  {
    id: 'multiple',
    title: 'Multiple',
    options: [
      {
        label: 'S',
        weight: 1,
      },
      {
        label: 'M',
        weight: 3,
      },
      {
        label: 'L',
        weight: 5,
      },
    ],
  },
  {
    id: 'custom',
    title: 'Custom',
    options: null,
  },
];

const optionsMatch = (a, b) => {
  if (!a || !b) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i].label !== b[i].label) return false;
    if (a[i].weight !== b[i].weight) return false;
  }
  return true;
};

const detectOption = (options) => {
  return OPTIONS.find((option) => optionsMatch(option.options, options)) || OPTIONS[OPTIONS.length - 1];
};

class EditTask extends React.Component {
  state = {
    id: null,
    title: '',
    users: {},
    invites: [],
    option: 'simple',
    options: [],
    optionDialogOpen: false,
    inviteDialogOpen: false,
    newOption: {
      label: '',
      weight: '',
    },
    inviteUser: {
      name: '',
      email: '',
    },
    changed: false,
  };

  currentOptions = () => {
    const selectedOption = OPTIONS.find((option) => option.id === this.state.option) || OPTIONS[0];
    const options = selectedOption.options || this.state.options || [];
    return options.map(({ label, weight }) => ({
      label,
      weight: parseInt(weight, 10),
    }))
  };

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value, changed: true });
  };

  handleOptionClick = (option) => {
    this.setState({ option: option.id, changed: true });
  };

  closeOptionDialog = () => {
    this.setState({ optionDialogOpen: false });
  };

  closeInviteDialog = () => {
    this.setState({ inviteDialogOpen: false });
  };

  openOptionDialog = () => {
    this.setState({
      newOption: {
        label: '',
        weight: '',
      },
      optionDialogOpen: true,
    });
  };

  openInviteDialog = async () => {
    const supportsContactPicker = ('contacts' in navigator && 'ContactsManager' in window);
    if (!supportsContactPicker) {
      this.setState({
        inviteUser: {
          name: '',
          email: '',
        },
        inviteDialogOpen: true,
      });
      return;
    }

    try {
      const contacts = await navigator.contacts.select(['name', 'email'], { multiple: false });
      if (!contacts || contacts.length !== 1) return;

      const contact = contacts[0];
      if (!contact.name || !contact.name.length || !contact.email || !contact.email.length) return;

      this.setState({
        inviteUser: {
          name: contact.name[0],
          email: contact.email[0],
        },
        inviteDialogOpen: true,
      });
    } catch (e) {
      this.setState({
        inviteUser: {
          name: '',
          email: '',
        },
        inviteDialogOpen: true,
      });
    }
  };

  addOption = () => {
    const newOption = this.state.newOption;
    this.setState((state) => ({
      newOption: {
        label: '',
        weight: '',
      },
      optionDialogOpen: false,
      options: [
        ...state.options,
        newOption
      ],
      changed: true,
    }));
  };

  inviteUser = () => {
    this.setState((state) => {
      const newState = {
        inviteUser: {
          name: '',
          email: '',
        },
        inviteDialogOpen: false,
      };

      if (!state.inviteUser || !state.inviteUser.email || !state.inviteUser.email.length ||
        !state.inviteUser.name || !state.inviteUser.name.length) {
        return newState;
      }

      newState.invites = state.invites ? [...state.invites, state.inviteUser] : [state.inviteUser];
      newState.changed = true;

      return newState;
    });
  };

  setNewOptionLabel = (event) => {
    const label = event.target.value;
    this.setState((state) => ({
      newOption: {
        ...state.newOption,
        label,
      },
    }));
  };

  setNewOptionWeight = (event) => {
    const weight = event.target.value;
    this.setState((state) => ({
      newOption: {
        ...state.newOption,
        weight,
      },
    }));
  };

  setInviteUserName = (event) => {
    const name = event.target.value;
    this.setState((state) => ({
      inviteUser: {
        ...state.inviteUser,
        name,
      },
    }));
  };

  setInviteUserEmail = (event) => {
    const email = event.target.value;
    this.setState((state) => ({
      inviteUser: {
        ...state.inviteUser,
        email,
      },
    }));
  };

  removeOption = (index) => {
    this.setState((state) => ({
      options: state.options.filter((_, i) => i !== index),
      changed: true,
    }));
  };

  removeUser = (id) => {
    this.setState((state) => {
      const users = { ...state.users };
      delete users[id];
      return {
        users,
        changed: true,
      };
    });
  };

  removeInvite = (index) => {
    this.setState((state) => ({
      invites: state.invites.filter((_, i) => i !== index),
      changed: true,
    }));
  };

  saveTask = () => {
    const task = {
      title: this.state.title,
      invites: this.state.invites,
      options: this.currentOptions(),
      users: this.state.users,
    };
    this.props.saveTask(task);
    this.setState({ changed: false });
  };

  render() {
    const { task, classes } = this.props;
    const { users, title, optionDialogOpen, inviteDialogOpen, changed, invites } = this.state;

    const selectedOption = this.state.option || 'simple';
    const user = firebase.auth().currentUser;
    const userId = user && user.uid;
    const ownsTask = task && userId && task.owner === userId;

    return <Container className={classes.root}>
      <TextField className={classes.title} label={"Title"} value={title} onChange={(e) => this.handleTitleChange(e)} />

      <Typography className={classes.groupLabel}>Options</Typography>
      <div className={classes.optionGroups}>
        {OPTIONS.map((option) =>
          <Chip
            key={option.id}
            className={option.id === selectedOption ? classes.optionBorder : null}
            variant={option.id === selectedOption ? 'default' : 'outlined'}
            color={option.id === selectedOption ? 'primary' : 'default'}
            onClick={() => this.handleOptionClick(option)}
            label={option.title} />
        )}
      </div>
      <div className={classes.options}>
        {this.currentOptions().map((option, index) => <Chip
          key={`${option.label}-${option.weight}-${index}`}
          label={`${option.label} ${option.weight}`}
          className={classes.chip}
          onDelete={selectedOption === 'custom' ? () => this.removeOption(index) : undefined}
        />)}
        {selectedOption === 'custom' && <Button
          color="secondary"
          size={'small'}
          onClick={() => this.openOptionDialog()}>
          Add
        </Button>}
      </div>

      <Typography className={classes.groupLabel}>Users</Typography>
      <div>
        {Object.entries(users).map(([id, user]) => <Chip
          key={id}
          className={classes.chip}
          avatar={<Avatar src={user.photoUrl} className={classes.avatar} />}
          label={user.name}
          onDelete={ownsTask && id !== userId ? () => this.removeUser(id) : undefined}
        />)}
      </div>

      <Typography className={classes.groupLabel}>Invites</Typography>
      <div>
        {invites.map((invite, index) => <Chip
          key={`${invite.name}-${invite.email}-${index}`}
          className={classes.chip}
          avatar={<Avatar className={classes.avatarInvite}>{invite.name[0].toUpperCase()}</Avatar>}
          label={invite.name}
          onDelete={() => this.removeInvite(index)}
        />)}
        <Button
          color="secondary"
          size={'small'}
          onClick={() => this.openInviteDialog()}>
          Invite
        </Button>
      </div>

      <Snackbar open={changed}>
        <SnackbarContent
          classes={{ root: classes.snackbar }}
          message={'Unsaved changes'}
          action={
            <Button color="inherit" size="small" variant="outlined"
                    onClick={() => this.saveTask()}>
              Save
            </Button>
          }
        />
      </Snackbar>

      <Dialog open={optionDialogOpen} onClose={() => this.closeOptionDialog()}>
        <DialogTitle>Add option</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Label"
            fullWidth
            value={this.state.newOption.label}
            onChange={(e) => this.setNewOptionLabel(e)}
          />
          <TextField
            margin="dense"
            label="Weight"
            type="number"
            fullWidth
            value={this.state.newOption.weight}
            onChange={(e) => this.setNewOptionWeight(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.addOption()} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={inviteDialogOpen} onClose={() => this.closeInviteDialog()}>
        <DialogTitle>Invite user</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={this.state.inviteUser.name}
            onChange={(e) => this.setInviteUserName(e)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={this.state.inviteUser.email}
            onChange={(e) => this.setInviteUserEmail(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.inviteUser()} color="primary">
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </Container>;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.taskId === 'new') {
      if (state.id === 'new') return null;
      const user = firebase.auth().currentUser;
      const userId = user && user.uid;

      return {
        id: 'new',
        title: '',
        users: {
          [userId]: {
            name: user.displayName,
            photoUrl: user.photoURL,
            joinedAt: new Date().valueOf(),
            u: true,
          }
        },
        invites: [],
        option: 'simple',
        options: [],
        optionDialogOpen: false,
        inviteDialogOpen: false,
        changed: false,
      };
    }

    if (!props.task || props.task.id === state.id) return null;

    const { id, title, users, invites } = props.task;
    const option = detectOption(props.task.options).id;
    const options = option === 'custom' ? props.task.options : [];

    return {
      id,
      title,
      users,
      invites,
      option,
      options,
      optionDialogOpen: false,
      inviteDialogOpen: false,
      changed: false,
    };
  }
}

export default withStyles(styles)(EditTask);
