import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SwipeableListItem from './SwipeableListItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {},
  entry: {
    display: 'flex',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 24,
  },
  entryDisabled: {
    display: 'flex',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 24,
    backgroundColor: '#ddd !important',
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: 8,
    alignSelf: 'center',
  },
  textArea: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightColumn: {
    textAlign: 'center',
  },
  topRow: {
    fontSize: '0.9rem',
    lineHeight: 1.5,
    fontWeight: 300,
  },
  bottomRow: {
    fontSize: '0.7rem',
    lineHeight: 1.5,
    fontWeight: 300,
  },
  snackbar: {
    backgroundColor: '#7cb342',
  },
});

class ActionLog extends React.Component {
  state = {
    currentSnackbar: null,
    nextSnackbar: null,
  };

  closeSnackbar(reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState(({ currentSnackbar }) => (currentSnackbar !== null) ? { currentSnackbar: null } : { nextSnackbar: null });
  }

  closedSnackbar() {
    if (this.state.nextSnackbar) {
      this.setState(({ nextSnackbar }) => ({
        currentSnackbar: nextSnackbar,
        nextSnackbar: null,
      }));
    }
  }

  removeLog(log) {
    this.props.removeActionLog(log).then(() => {
      this.setState(({ currentSnackbar }) => (currentSnackbar !== null) ?
        { currentSnackbar: null, nextSnackbar: log } : { currentSnackbar: log });
    });
  }

  undoRemove() {
    this.props.addActionLog(this.state.currentSnackbar);
    this.closeSnackbar();
  }

  render() {
    const { actionLog, users, classes } = this.props;
    const { currentSnackbar } = this.state;

    const logs = actionLog || [];
    logs.sort((a, b) => b.timestamp - a.timestamp);
    return <div className={classes.root}>
      {logs.filter((log) => users[log.performedBy]).map((log) =>
        <SwipeableListItem key={log.timestamp}
                           className={users[log.performedBy].joinedAt > log.timestamp ? classes.entryDisabled : classes.entry}
                           onDeleted={() => this.removeLog(log)}>
          <Avatar imgProps={{ draggable: "false" }} src={users[log.performedBy].photoUrl} className={classes.avatar} />
          <div className={classes.textArea}>
            <div>
              <Typography variant="subtitle1" component="h3"
                          className={classes.topRow}>{users[log.performedBy].name}</Typography>
              <Typography variant="subtitle1" component="h3"
                          className={classes.bottomRow}>{moment(log.timestamp).fromNow()}</Typography>
            </div>
            <div className={classes.rightColumn}>
              <Typography variant="subtitle1" component="h3" className={classes.topRow}>{log.option.label}</Typography>
              <Typography variant="subtitle1" component="h3"
                          className={classes.bottomRow}>{log.option.weight}</Typography>
            </div>
          </div>
        </SwipeableListItem>
      )}

      <Snackbar open={currentSnackbar !== null}
                autoHideDuration={3000}
                onClose={(event, reason) => this.closeSnackbar(reason)}
                onExited={() => this.closedSnackbar()}>
        <SnackbarContent
          classes={{ root: classes.snackbar }}
          message={'Task entry removed'}
          action={
            <Button color="inherit" size="small" variant="outlined"
                    onClick={() => currentSnackbar !== null && this.undoRemove()}>
              Undo
            </Button>
          }
        />
      </Snackbar>

    </div>;
  }
}

export default withStyles(styles)(ActionLog);
