import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: 32,
    marginLeft: 'auto',
    '& > *': {
      marginLeft: 16,
    },
  },
});

class Invite extends React.Component {
  render() {
    const { classes, search, accept, decline } = this.props;
    const params = new URLSearchParams(search);

    return <Container className={classes.root}>
      <Typography>Do you want to accept the invite to task <b>{params.get('title')}</b>?</Typography>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" onClick={() => accept(params.get('id'))}>Accept</Button>
        <Button variant="outlined" color="default" onClick={() => decline(params.get('id'))}>Decline</Button>
      </div>
    </Container>;
  }
}

export default withStyles(styles)(Invite);
