import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  icon: {
    color: 'inherit',
  },
});

class InviteHeader extends React.Component {
  render() {
    const { classes, loading, cancel } = this.props;

    return <AppBar position='fixed'>
      <Toolbar>
        <Typography variant='h6' className={classes.title}>
          Accept invite
        </Typography>
        <IconButton
          className={classes.icon}
          size={'small'}
          onClick={cancel}>
          <CloseIcon />
        </IconButton>
      </Toolbar>
      {loading && <LinearProgress className={classes.progress} />}
    </AppBar>;
  }
}

export default withStyles(styles)(InviteHeader);
