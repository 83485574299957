import { createMuiTheme } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';

const theme = createMuiTheme({
  palette: {
    primary: { main: deepPurple[400] },
    secondary: { main: indigo[800] },
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiCardActionArea: {
      focusHighlight: {
        display: 'none'
      }
    }
  }
});

export default theme;
