import React from 'react';
import firebase from 'firebase/app';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonBase from '@material-ui/core/ButtonBase';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  title: {
    flexGrow: 1,
    '& > a': {
      textDecoration: 'none',
      color: 'inherit',
    }
  },
  ripple: {
    borderRadius: '50%',
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
});

class TasksHeader extends React.Component {
  state = {
    anchor: null,
  };

  handleClick = (event) => {
    this.setState({ anchor: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchor: null });
  };

  handleLogout = () => {
    this.handleClose();
    firebase.auth().signOut();
  };

  handleRefresh = () => {
    this.handleClose();
    this.props.refresh();
  };

  render() {
    const { classes, loading } = this.props;
    const { anchor } = this.state;

    return <AppBar position='fixed'>
      <Toolbar>
        <Typography variant='h6' className={classes.title}>
          <Link to='/'>My tasks</Link>
        </Typography>
        <ButtonBase focusRipple className={classes.ripple}>
          <Avatar src={firebase.auth().currentUser.photoURL} onClick={this.handleClick} />
        </ButtonBase>
        <Menu
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleRefresh}>Refresh</MenuItem>
          <Divider />
          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
      {loading && <LinearProgress className={classes.progress} />}
    </AppBar>;
  }
}

export default withStyles(styles)(TasksHeader);
