import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider as MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import '@firebase/firestore/dist/index.cjs.min';
import 'firebase/auth';
import 'firebase/functions';
import 'typeface-roboto';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import mainTheme from './theme';
import TasksHeader from './components/TasksHeader';
import SignIn from './components/SignIn';
import Tasks from './components/Tasks';
import ActionLog from './components/ActionLog';
import ActionLogHeader from './components/ActionLogHeader';
import EditTask from './components/EditTask';
import EditTaskHeader from './components/EditTaskHeader';
import Invite from './components/Invite';
import InviteHeader from './components/InviteHeader';
import './index.css';

OfflinePluginRuntime.install();

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyDQVb0ptS7mN-HiZqUy0-gsJD0nm0gzUgI",
  authDomain: "tina-tina.firebaseapp.com",
  databaseURL: "https://tina-tina.firebaseio.com",
  projectId: "tina-tina",
  storageBucket: "tina-tina.appspot.com",
  messagingSenderId: "724475471828",
  appId: "1:724475471828:web:93bc4d7938e10fcb579841"
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firebase.firestore().enablePersistence().catch((err) => {
  console.error(err);
});

const appElement = document.getElementById('app');
disableBodyScroll(appElement);

const styles = (theme) => ({
  content: {
    height: '100%',
    overflowY: 'auto',
    padding: 0,
  },
});

class Tina extends React.Component {
  state = {
    isSignedIn: false,
    loading: true,
    progress: 0,
    tasks: {},
  };

  targetRef = React.createRef();
  targetElement = null;

  updateTasks() {
    this.setState(({ progress }) => ({ progress: progress + 1 }));
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;
    return db.collection('tasks').where(`users.${user.uid}.u`, '==', true).get().then((querySnapshot) => {
      const tasks = {};
      querySnapshot.forEach((doc) => {
        tasks[doc.id] = doc.data();
      });
      if (this.targetElement) {
        enableBodyScroll(this.targetElement);
        this.targetElement = null;
      }
      const newState = { tasks };
      this.setState(newState);
      this.targetElement = this.targetRef.current;
      disableBodyScroll(this.targetElement);
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    });
  }

  async addActionLog(taskId, newActionLog) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc(taskId);

      await db.runTransaction(async (transaction) => {
        const task = await transaction.get(ref);
        if (!task.exists) throw 'Task does not exist!';
        const actionLog = task.data().actionLog || [];
        actionLog.push(newActionLog);
        transaction.update(ref, { actionLog });
      });

      await this.updateTasks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  navigateHome(history) {
    history.replace('/');
  }

  editActionLog(history, taskId) {
    history.push(`/tasks/${taskId}/log`);
  }

  editTask(history, taskId) {
    history.push(`/tasks/${taskId}`);
  }

  openNewTask(history) {
    history.push('/tasks/new');
  }

  async deleteActionLog(taskId, oldActionLog) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc(taskId);

      await db.runTransaction(async (transaction) => {
        const task = await transaction.get(ref);
        if (!task.exists) throw 'Task does not exist!';
        const actionLog = task.data().actionLog || [];
        const actionLogIndex = actionLog.findIndex((log) => log.timestamp === oldActionLog.timestamp);
        if (actionLogIndex >= 0) {
          actionLog.splice(actionLogIndex, 1);
          transaction.update(ref, { actionLog });
        }
      });

      await this.updateTasks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async deleteAllActionLogs(taskId) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc(taskId);

      await db.runTransaction(async (transaction) => {
        const task = await transaction.get(ref);
        if (!task.exists) throw 'Task does not exist!';
        transaction.update(ref, { actionLog: [] });
      });

      await this.updateTasks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async removeTask(history, taskId, ownsTask) {
    if (!ownsTask) {
      try {
        this.setState(({ progress }) => ({ progress: progress + 1 }));
        const db = firebase.firestore();
        const ref = db.collection('tasks').doc(taskId);

        await db.runTransaction(async (transaction) => {
          const task = await transaction.get(ref);
          if (!task.exists) throw 'Task does not exist!';

          const user = firebase.auth().currentUser;

          const users = task.data().users || {};
          delete users[user.uid];

          transaction.update(ref, { users });
        });

        this.navigateHome(history);
        await this.updateTasks();
      } catch (error) {
        console.error(error);
      } finally {
        this.setState(({ progress }) => ({ progress: progress - 1 }));
      }
      return;
    }

    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc(taskId);

      await ref.delete();

      this.navigateHome(history);
      await this.updateTasks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async createTask(history, taskUpdate) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc();

      await db.runTransaction(async (transaction) => {
        const newInvites = taskUpdate.invites.map((invite, index) => ({ invite, index }));
        for (const { invite, index } of newInvites) {
          const inviteRef = db.collection('invites').doc();
          transaction.set(inviteRef, {
            ...invite,
            taskId: ref.id,
          });
          newInvites[index].invite.id = inviteRef.id;
        }

        const user = firebase.auth().currentUser;

        taskUpdate.owner = user.uid;
        taskUpdate.invites = newInvites.map(({ invite }) => invite);
        transaction.set(ref, taskUpdate);
      });

      await this.updateTasks();
      this.editTask(history, ref.id);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async saveTask(history, taskId, taskUpdate) {
    if (taskId === 'new') return this.createTask(history, taskUpdate);

    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      const db = firebase.firestore();
      const ref = db.collection('tasks').doc(taskId);

      await db.runTransaction(async (transaction) => {
        const task = await transaction.get(ref);
        if (!task.exists) throw 'Task does not exist!';
        const taskData = task.data();

        const newInvites = taskUpdate.invites.map((invite, index) => ({ invite, index }));
        const addedInvites = newInvites.filter(({ invite }) => !invite.id);
        const removedInvites = taskData.invites.filter((invite) => !taskUpdate.invites.find((i) => i.id === invite.id));

        for (const { invite, index } of addedInvites) {
          const inviteRef = db.collection('invites').doc();
          transaction.set(inviteRef, {
            ...invite,
            taskId,
          });
          newInvites[index].invite.id = inviteRef.id;
        }

        for (const invite of removedInvites) {
          transaction.delete(db.collection('invites').doc(invite.id));
        }

        taskUpdate.invites = newInvites.map(({ invite }) => invite);
        transaction.update(ref, taskUpdate);
      });

      await this.updateTasks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async acceptInvite(history, inviteId) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      await firebase.functions().httpsCallable('acceptInvite')({ invite: inviteId });
      await this.updateTasks();
      this.navigateHome(history);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  async declineInvite(history, inviteId) {
    try {
      this.setState(({ progress }) => ({ progress: progress + 1 }));
      await firebase.functions().httpsCallable('declineInvite')({ invite: inviteId });
      await this.updateTasks();
      this.navigateHome(history);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState(({ progress }) => ({ progress: progress - 1 }));
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (this.targetElement) {
        enableBodyScroll(this.targetElement);
        this.targetElement = null;
      }

      this.setState({ isSignedIn: !!user, loading: false });

      if (user) {
        this.updateTasks();
      }
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
    clearAllBodyScrollLocks();
  }

  render() {
    const { classes } = this.props;
    const { tasks, progress, loading } = this.state;

    if (loading || !this.state.isSignedIn) {
      return <SignIn loading={loading} />;
    }

    return <Router>
      <Switch>
        <Route path='/tasks/:taskId/log' render={({ match: { params: { taskId } }, history }) => (
          <ActionLogHeader
            loading={progress > 0}
            cancel={() => this.navigateHome(history)}
            deleteAll={() => this.deleteAllActionLogs(taskId)}
          />
        )} />
        <Route path='/tasks/:taskId' render={({ match: { params: { taskId } }, history }) => (
          <EditTaskHeader
            loading={progress > 0}
            task={tasks[taskId]}
            cancel={() => this.navigateHome(history)}
            removeTask={(ownsTask) => this.removeTask(history, taskId, ownsTask)}
          />
        )} />
        <Route path='/invite' render={({ history }) => (
          <InviteHeader
            loading={progress > 0}
            cancel={() => this.navigateHome(history)}
          />
        )} />
        <Route path='/' render={() => (
          <TasksHeader
            loading={progress > 0}
            refresh={() => this.updateTasks()}
          />
        )} />
      </Switch>
      <Container className={classes.content} ref={this.targetRef}>
        <Toolbar />
        <Switch>
          <Route path='/tasks/:taskId/log' render={({ match: { params: { taskId } } }) => (
            <ActionLog
              actionLog={tasks[taskId] && tasks[taskId].actionLog}
              users={tasks[taskId] && tasks[taskId].users}
              addActionLog={(actionLog) => this.addActionLog(taskId, actionLog)}
              removeActionLog={(index) => this.deleteActionLog(taskId, index)} />
          )} />
          <Route path='/tasks/:taskId' render={({ match: { params: { taskId } }, history }) => (
            <EditTask
              taskId={taskId}
              task={tasks[taskId]}
              saveTask={(task) => this.saveTask(history, taskId, task)}
            />
          )} />
          <Route path='/invite' render={({ location: { search }, history }) => (
            <Invite
              search={search}
              accept={(inviteId) => this.acceptInvite(history, inviteId)}
              decline={(inviteId) => this.declineInvite(history, inviteId)}
            />
          )} />
          <Route exact path='/' render={({ history }) => (
            <Tasks
              tasks={tasks}
              editActionLog={(id) => this.editActionLog(history, id)}
              addActionLog={(id, actionLog) => this.addActionLog(id, actionLog)}
              editTask={(id) => this.editTask(history, id)}
              createTask={() => this.openNewTask(history)}
            />
          )} />
          <Route path="*">
            404
          </Route>
        </Switch>
      </Container>
    </Router>;
  }
}

const TinaStyled = withStyles(styles)(Tina);

const template =
  <MuiThemeProvider theme={mainTheme}>
    <React.Fragment>
      <CssBaseline />
      <TinaStyled />
    </React.Fragment>
  </MuiThemeProvider>;

ReactDOM.render(template, appElement);
