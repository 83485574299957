import React from 'react';
import Task from './Task';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = (theme) => ({
  root: {
    padding: 16,
    '& > :first-child': {
      marginTop: 0,
    },
    paddingBottom: 90,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

const Tasks = ({ tasks, addActionLog, editActionLog, editTask, classes, createTask }) => (<div className={classes.root}>
  {tasks && Object.entries(tasks).map(([id, task]) => <Task
    key={id}
    task={task}
    editActionLog={() => editActionLog(id)}
    addActionLog={(actionLog) => addActionLog(id, actionLog)}
    editTask={() => editTask(id)}
  />)}
  <Fab color="secondary" className={classes.fab} onClick={createTask}>
    <AddIcon />
  </Fab>
</div>);

export default withStyles(styles)(Tasks);
