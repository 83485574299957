import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    display: 'flex',
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 4,
  },
  avatar: {
    width: 23,
    height: 23,
    marginRight: 8,
  },
  name: {
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  diff: {
    marginLeft: 8,
    fontSize: '0.9rem',
    fontWeight: 300,
  },
  options: {
    marginLeft: 'auto',
  },
  option: {
    fontSize: '0.9rem',
    width: 23,
    height: 23,
    marginLeft: 8,
  },
});

const UserRow = ({ user, diff, classes, opened, options, addActionLog }) => <div className={classes.root}>
  <Avatar src={user.photoUrl} className={classes.avatar} />
  <Typography variant="caption" className={classes.name}>
    {user.name}
  </Typography>
  {opened && <Typography variant="caption" className={classes.diff}>
    {diff < 0 ? '👑' : diff}
  </Typography>}
  <div className={classes.options}>
    {options.map((option, index) => (
      <IconButton
        component={'div'}
        size={'small'}
        key={index}
        className={classes.option}
        onClick={(event) => {
          event.stopPropagation();
          addActionLog(option);
        }}
        onMouseDown={(event) => event.stopPropagation()}
        onTouchStart={(event) => event.stopPropagation()}
      >
        {option.label}
      </IconButton>
    ))}
  </div>
</div>;

export default withStyles(styles)(UserRow);
