import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Logo from '../logo.svg';

const styles = (theme) => ({
  logo: {
    paddingTop: 50,
    paddingBottom: 40,
    marginBottom: '-1em',
  },
});

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      buttonColor: '#red',
    }
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
};

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.logo = React.createRef();
  }

  componentDidMount() {
    const transform = this.logo.current.querySelector('animateTransform');
    if (this.props.loading) {
      transform.beginElement();
    } else {
      transform.endElement();
    }
  }

  componentDidUpdate(prevProps) {
    const transform = this.logo.current.querySelector('animateTransform');
    if (!prevProps.loading && this.props.loading) {
      transform.beginElement();
    } else if (prevProps.loading && !this.props.loading) {
      transform.endElement();
    }
  }

  render() {
    const { classes, loading } = this.props;

    return <div>
      <Logo width={'100%'} height={180} className={classes.logo} ref={this.logo} />
      {!loading && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />}
    </div>;
  }
}

export default withStyles(styles)(SignIn);
